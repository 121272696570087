import React, {useState, useEffect} from 'react';
import VideoPlayer from './VideoPlayer';
import './index.css';

function App() {
    const [videoLeft, setVideoLeft] = useState('');
    const [startLeft, setStartLeft] = useState(0);
    const [isPlayerReadyLeft, setIsPlayerReadyLeft] = useState(false);
    const [muteLeft, setMuteLeft] = useState(false);

    const [videoRight, setVideoRight] = useState('');
    const [startRight, setStartRight] = useState(0);
    const [isPlayerReadyRight, setIsPlayerReadyRight] = useState(false);
    const [muteRight, setMuteRight] = useState(false);

    const [additionalVideos, setAdditionalVideos] = useState([]);
    const [videoNew, setVideoNew] = useState('');
    const [play, setPlay] = useState(false);

    const [videoHeight, setVideoHeight] = useState('85vh');

    function updateVideoHeight() {
        // const numberOfRows = Math.ceil(newVideos.length / 2); // 2 vidéos par ligne
        const screenHeight = window.innerHeight;
        // const calculatedHeight = screenHeight / numberOfRows;
        const calculatedHeight = screenHeight / 2 - 100;
        setVideoHeight(`${calculatedHeight}px`);
    }

    // Fonction pour ajouter un champ de vidéo supplémentaire
    const addVideoInput = () => {
        const newVideos = [...additionalVideos, {url: '', startSeconds: 0}];
        setAdditionalVideos(newVideos);
        updateVideoHeight();
    };

    // Fonction pour mettre à jour les vidéos supplémentaires
    const updateAdditionalVideo = (index, value, field) => {
        const newVideos = [...additionalVideos];
        newVideos[index][field] = value;
        setAdditionalVideos(newVideos);
    };

    // Fonction pour ajouter une nouvelle vidéo
    const addVideo = () => {
        if (videoNew) {
            setAdditionalVideos([...additionalVideos, videoNew]);
            setVideoNew(''); // Réinitialiser le champ de saisie
        }
    };

    const handlePlayerReadyLeft = () => {
        setIsPlayerReadyLeft(true);
    };

    const handlePlayerReadyRight = () => {
        setIsPlayerReadyRight(true);
    };

    // Gérer la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        setPlay(false); // Réinitialiser la lecture
    };

    // Gérer le bouton de lecture/arrêt
    const handlePlayStop = () => {
        setPlay(!play);
    };

    // Fonction pour réinitialiser les vidéos
    const handleReset = () => {
        setPlay(false); // Arrêter les vidéos

        // Réinitialiser les vidéos avec les valeurs actuelles de décalage
        if (videoLeft) {
            setVideoLeft('');
            setTimeout(() => setVideoLeft(videoLeft), 100);
        }
        if (videoRight) {
            setVideoRight('');
            setTimeout(() => setVideoRight(videoRight), 100);
        }
        setAdditionalVideos(additionalVideos.map(video => ({ ...video, url: '' })));
        setTimeout(() => setAdditionalVideos(additionalVideos), 100);
    };

    const isPlayButtonDisabled = !videoLeft || !videoRight; // Le bouton PLAY est désactivé si l'une des URLs est vide

    useEffect(() => {
        // Récupérez les paramètres de recherche de l'URL de la fenêtre
        const queryParams = new URLSearchParams(window.location.search);
        // videoLeft
        const videoLeftParam = queryParams.get('videoLeft');
        const startLeftParam = Number(queryParams.get('startLeft'));
        const muteLeftParam = queryParams.get('muteLeft');
        if (videoLeftParam) setVideoLeft(videoLeftParam);
        if (startLeftParam) setStartLeft(startLeftParam);
        if (muteLeftParam) setMuteLeft(muteLeftParam);

        // videoRight
        const videoRightParam = queryParams.get('videoRight');
        const startRightParam = Number(queryParams.get('startRight'));
        const muteRightParam = queryParams.get('muteRight');
        if (videoRightParam) setVideoRight(videoRightParam);
        if (startRightParam) setStartRight(startRightParam);
        if (muteRightParam) setMuteRight(muteRightParam);

        // Gérer les vidéos additionnelles
        const newVideos = [];
        for (let i = 1; queryParams.get(`video${i}`); i++) {
            newVideos.push({
                url: queryParams.get(`video${i}`),
                startSeconds: Number(queryParams.get(`start${i}`) || 0),
                mute: queryParams.get(`mute${i}`),
            });
        }
        if (newVideos.length > 0) {
            setAdditionalVideos(newVideos);
            updateVideoHeight();
        }

        // Générer un tableau pour les vidéos additionnelles à partir des paramètres GET
        /*const videosFromParams = [];
        queryParams.forEach((value, key) => {
            if (key.startsWith('video') && key !== 'videoLeft' && key !== 'videoRight') {
                // Supposons que les identifiants sont numériques après "video"
                const index = parseInt(key.replace('video', ''), 10);
                if (!isNaN(index)) {
                    videosFromParams[index] = { url: value, startSeconds: 0 };
                }
            }
        });
        // Mettez à jour les états avec les vidéos obtenues des paramètres GET
        setAdditionalVideos(videosFromParams);*/
    }, []);

    /*useEffect(() => {
        //<base url>?videos=[left => [id, start], right => [id, start], 1 => [id, start], 2 => [id, start], ...]
        // TODO en amont :
        const videos = {
            left: ["id_left", 10],
            right: ["id_right", 5],
            additional: [
                ["id1", 0],
                ["id2", 5]
            ]
        };

        const encodedVideos = encodeURIComponent(JSON.stringify(videos));

        const baseUrl = "http://votresite.com/chemin";
        const urlWithParams = `${baseUrl}?videos=${encodedVideos}`;

        const queryParams = new URLSearchParams(window.location.search);
        const videosParam = queryParams.get('videos');

        if (videosParam) {
            try {
                const videos = JSON.parse(decodeURIComponent(videosParam));

                // Ici, vous devez convertir les données JSON en états de votre application
                if (videos.left) {
                    setVideoLeft(videos.left[0]);
                    setStartLeft(videos.left[1]);
                }
                if (videos.right) {
                    setVideoRight(videos.right[0]);
                    setStartRight(videos.right[1]);
                }
                if (videos.additional) {
                    setAdditionalVideos(videos.additional.map(video => ({
                        url: video[0],
                        startSeconds: video[1],
                        play: false
                    })));
                }
            } catch (error) {
                console.error("Erreur lors du décodage des paramètres de l'URL", error);
            }
        }
    }, []);*/

    return (
        <div className="App">
            <div className="flex flex-col items-center justify-center min-h-screen bg-neutral-50">
                <div className="flex justify-around w-full">
                    <div className="input input-bordered">
                        <button
                            onClick={handlePlayStop}
                            disabled={isPlayButtonDisabled} // Désactive le bouton si une des vidéos n'est pas chargée
                            className={`text-white font-bold py-2 px-4 m-1 rounded-full ${isPlayButtonDisabled ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'}`}
                        >
                            {play ? "STOP" : "PLAY"}
                        </button>
                        <button
                            onClick={handleReset}
                            disabled={isPlayButtonDisabled}
                            className={`text-white font-bold py-2 px-4 m-1 rounded-full ${isPlayButtonDisabled ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'}`}>
                            RESET
                        </button>
                        <button onClick={addVideoInput}
                                className={`text-white font-bold py-2 px-4 m-1 rounded-full bg-red-500 hover:bg-red-700`}>
                            ADD VIDEO
                        </button>
                    </div>
                </div>

                {/* Conteneurs pour les vidéos et les champs URL */}
                <div className="flex flex-col w-full h-full mt-4 md:flex-row">

                    {/* Cadre et champ URL pour la vidéo Left */}
                    <div className="flex-1">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full items-center pl-4 pr-4">
                                <input
                                    type="text"
                                    placeholder="Youtube URL / Youtube ID #left"
                                    value={videoLeft}
                                    onChange={(e) => setVideoLeft(e.target.value)}
                                    className="input input-bordered w-full max-w-md mb-2 mr-4 p-2"
                                />
                                <div className="flex w-full items-center mb-2">
                                    <label htmlFor="startLeft" className="w-full mr-2 text-right">
                                        Start (in seconds):
                                    </label>
                                    <input
                                        id="startLeft"
                                        type="number"
                                        step="0.01"
                                        value={startLeft}
                                        onChange={(e) => setStartLeft(e.target.value)}
                                        className="input input-bordered p-2"
                                    />
                                </div>
                            </div>
                            <div
                                className="relative w-full bg-gray-900 border-2 border-gray-800 rounded-lg h-[85vh]" style={{ height: videoHeight }}>
                                {!videoLeft && (
                                    <div
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <span className="text-gray-500">Your #left video will appear here</span>
                                    </div>
                                )}
                                {videoLeft && (
                                    <VideoPlayer url={videoLeft} startSeconds={startLeft} play={play} mute={muteLeft} onPlayerReady={handlePlayerReadyLeft}/>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Cadre et champ URL pour la vidéo Right */}
                    <div className="flex-1">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full items-center pl-4 pr-4">
                                <input
                                    type="text"
                                    placeholder="Youtube URL / Youtube ID #right"
                                    value={videoRight}
                                    onChange={(e) => setVideoRight(e.target.value)}
                                    className="input input-bordered w-full max-w-md mb-2 mr-4 p-2"
                                />
                                <div className="flex w-full items-center mb-2">
                                    <label htmlFor="startRight" className="w-full mr-2 text-right">
                                        Start (in seconds):
                                    </label>
                                    <input
                                        id="startRight"
                                        type="number"
                                        step="0.01"
                                        value={startRight}
                                        onChange={(e) => setStartRight(e.target.value)}
                                        className="input input-bordered p-2"
                                    />
                                </div>
                            </div>
                            <div
                                className="relative w-full bg-gray-900 border-2 border-gray-800 rounded-lg h-[85vh]" style={{ height: videoHeight }}>
                                {!videoRight && (
                                    <div
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <span className="text-gray-500">Your #right video will appear here</span>
                                    </div>
                                )}
                                {videoRight && (
                                    <VideoPlayer url={videoRight} startSeconds={startRight} play={play} mute={muteRight} onPlayerReady={handlePlayerReadyRight}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contrôles pour les vidéos */}
                {/*<div className="flex justify-around w-full mt-4">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="flex-1 items-center">
                                <button
                                    type="submit"
                                    disabled={!videoLeft && !videoRight}
                                    className="btn btn-primary"
                                >
                                    LOAD
                                </button>
                            </div>
                        </div>
                    </form>
                </div>*/}

                <div className="flex w-full h-full mt-4 md:flex-row">
                    {/* Rendu des champs de vidéo supplémentaires */}
                    {additionalVideos.map((video, index) => (
                        <div key={`video-${index}`} className="flex-1">
                            <div className="flex flex-col items-center">
                                <div className="flex w-full items-center pl-4 pr-4">
                                    <input
                                        type="text"
                                        placeholder="Youtube URL / Youtube ID"
                                        value={video.url}
                                        onChange={(e) => updateAdditionalVideo(index, e.target.value, 'url')}
                                        className="input input-bordered w-full mb-2 mr-4 p-2"
                                    />
                                    <div className="flex w-full items-center mb-2">
                                        <label htmlFor={"startNew" + index} className="w-full mr-2 text-right">
                                            Start (in seconds):
                                        </label>
                                        <input
                                            id={"startNew" + index}
                                            type="number"
                                            step="0.01"
                                            value={video.startSeconds}
                                            onChange={(e) => updateAdditionalVideo(index, e.target.value, 'startSeconds')}
                                            className="input input-bordered w-full max-w-md mr-4 p-2"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="relative w-full bg-gray-900 border-2 border-gray-800 rounded-lg h-[85vh]" style={{ height: videoHeight }}>
                                    {!video.url && (
                                        <div
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <span className="text-gray-500">Your video will appear here</span>
                                        </div>
                                    )}
                                    {video.url && (
                                        <VideoPlayer url={video.url} startSeconds={video.startSeconds} play={play} mute={video.mute} onPlayerReady={true}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/*<div className="flex-1">*/}
                    {/*    <div className="flex flex-col items-center">*/}
                    {/*        <input*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Youtube URL / Youtube ID"*/}
                    {/*            value={videoNew}*/}
                    {/*            onChange={(e) => setVideoNew(e.target.value)}*/}
                    {/*            className="input input-bordered w-full max-w-md mb-2"*/}
                    {/*        />*/}
                    {/*        {videoNew && (*/}
                    {/*            <div*/}
                    {/*                className="relative w-full bg-gray-200 border-2 border-dashed border-gray-300 rounded-lg h-96">*/}
                    {/*                <VideoPlayer url={videoNew} startSeconds={startNew} play={play}/>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </div>*/}

                    {/*    /!*{additionalVideos.map((videoUrl, index) => (*!/*/}
                    {/*    /!*    <VideoPlayer key={index} url={videoUrl} play={false}/>*!/*/}
                    {/*    /!*))}*!/*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );

}

export default App;