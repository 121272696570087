import React from 'react';
import YouTube from 'react-youtube';

function VideoPlayer({ url, startSeconds, play, mute, onPlayerReady }) {
    // Référence pour le player YouTube
    const playerRef = React.useRef(null);

    // Extraire l'ID de la vidéo YouTube à partir de l'URL
    const videoId = getYouTubeID(url);

    // Options pour le lecteur YouTube
    const opts = {
        width: '100%', // Assurez-vous que la vidéo prend toute la largeur
        height: '100%', // Assurez-vous que la vidéo prend toute la hauteur
        playerVars: {
            autoplay: play ? 1 : 0,
            start: startSeconds,
            mute: mute ? 1 : 0,
            controls: 1,
            modestbranding: 1,
            rel: 0,
        },
    };

    // Fonction pour extraire l'ID de la vidéo YouTube à partir de l'URL
    function getYouTubeID(url) {
        const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            // Gérer l'erreur ou retourner une valeur par défaut
            return url;
        }
    }

    // Fonction appelée quand le lecteur est prêt
    const onReady = (event) => {
        playerRef.current = event.target; // Stocke une référence au player
    };

    // Effet pour gérer la lecture et l'arrêt de la vidéo
    React.useEffect(() => {
        if (!playerRef.current || !onPlayerReady) return;

        if (play) {
            if (playerRef.current.getCurrentTime() !== 0 && playerRef.current.getCurrentTime() !== startSeconds) {
                playerRef.current.playVideo(); // ne prend pas en charge les décimales
            } else {
                playerRef.current.seekTo(parseFloat(startSeconds), true);
            }
        } else {
            playerRef.current.pauseVideo();
        }
    }, [play]);

    return <YouTube videoId={videoId} opts={opts} onReady={onReady} className="h-full" />;
}

export default VideoPlayer;
